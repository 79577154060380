import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import LMSButton from "../../Common/LMSButton/LMSButton";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
  findRegionsByUser,
  getChildRegionsById,
  getCurrBarChartData,
} from "../../../../Services/EndPoints";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import "./MISDistFloodReportTable.css";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import moment from "moment";
import { misColumns, misDistReportConfig } from "./MISDistFloodReportConfig";
import { CSVLink } from "react-csv";
import CustomTable from "../../../../Components/UiComponents/Table/CustomTable";

const viewOptions = [
  {
    value: "Dist",
    id: "dist",
  },
  {
    value: "State",
    id: "state",
  },

  {
    value: "State Datewise",
    id: "state_date",
  },
  {
    value: "District Datewise",
    id: "dist_date",
  },
];
const newDateColumn = {
  Header: "Date",
  accessor: "date",
  disableSortBy: true,
  showInitially: true,
};
const newStateColumn = {
  Header: "State Name",
  accessor: "name",
  disableSortBy: true,
  showInitially: true,
};
export default function MISFloodReportTable() {
  const { t } = useTranslation();
  const [districtData, setDistrictData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [column, setColumn] = useState(misColumns);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectDisable, setSelectDisable] = useState(false);
  useEffect(() => {
    getDistrictData();
  }, []);

  const fetchTableData = async () => {
    console.log(selectedDate, selectedDistrictId, slctdBlkId);
    // await onChangeView();
    let data = await postDataWithBody(
      getCurrBarChartData + "get_district_mis_report",
      { reportedOn: selectedDate, districtId: selectedDistrictId },
      {}
    );
    setTableData(data);
  };
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        const formattedDistList = distList.map((item) => ({
          value: item.value,
          id: item.id,
        }));

        const updatedDistList = [
          { value: "All", id: "All" },
          ...formattedDistList,
        ];

        // Set the district data with "All" option first
        setDistrictData(updatedDistList);

        setSelectedDistrictId("All");
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };

  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }

  const onChangeView = (selectedView) => {
    setSelectedDistrictId("All");
    setSlctdBlkId("All");
    if (selectedView === "state") {
      const updatedColumns = [
        newStateColumn,
        ...misColumns.filter(
          (_, index) => index !== 0 && index !== 1 && index !== 2
        ),
      ];
      setSelectDisable(true);
      setColumn(updatedColumns);
    } else if (selectedView === "state_date") {
      const updatedColumns = [
        newDateColumn,
        newStateColumn,
        ...misColumns.filter(
          (_, index) => index !== 0 && index !== 1 && index !== 2
        ),
      ];
      setColumn(updatedColumns);
      setSelectDisable(true);
    } else if (selectedView === "dist_date") {
      const updatedColumns = [newDateColumn, ...misColumns];

      setColumn(updatedColumns);
      setSelectDisable(false);
    } else {
      setColumn(misColumns);
      setSelectDisable(false);
    }
  };
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }

  const headers = column.map((key, index) => ({
    label: t(key.Header),
    key: t(key.accessor),
  }));
  return (
    <div>
      <div className="flood__report--container">
        <div className="ps-2 d-flex gap-2">
          <div>
            <label className="mb-2">{"View"}</label>
            <SelectComponent
              id="view"
              // value={selectedView}
              // placeholder="All District"
              options={viewOptions}
              onChange={(e) => {
                onChangeView(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="mb-2">{t("floodreport.district")}</label>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              disabled={selectDisable}
            />
          </div>
          <div style={{ minWidth: "10rem" }}>
            <label className="mb-2">Block</label>
            <SelectComponent
              id="block_drop"
              value={slctdBlkId}
              onChange={setSelectedBlockIdVal}
              placeholder="All Blocks"
              options={blockData}
              disabled={selectDisable}
            />
          </div>
          <div className="">
            <label className="mb-2">{t("flooddisaster.datetxtfrom")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                // selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>
          <div className="">
            <label className="mb-2">{t("flooddisaster.datetxtto")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                // selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>

          <div className="d-flex align-items-end mb-3 gap-2">
            <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={fetchTableData}
            />
            <CSVLink
              data={tableData}
              headers={headers}
              filename={`MIS_Dist_Flood_Report_${selectedDate}.csv`}>
              <LMSButton size="btn_sm" label="Export" />
            </CSVLink>
          </div>
        </div>
      </div>
      <CustomTable
        data={[]}
        showFilter={false}
        columns={column}
        numberOfRows={7}
        // setSearchData={setSearchData}
        showExport={false}
        showReset={false}
        onExpand={false}
        showSearch={true}
        isHeaderFixed={true}
        // rowCount={rowCount} // Pass rowCount to the Table component
        enableColumnFilters={true}
        showFilterColumn={true}
        tableStyle={{
          border: "#f0f0f0",
          padding: "2px",
          borderRadius: "12px",
        }}
      />
    </div>
  );
}

import React, { useState, createContext, useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Table.css";
import Sortdownarrow from "../../IconComponents/Sortdownarrow";
import Sortuparrow from "../../IconComponents/Sortuparrow";
import Sortingarrow from "../../IconComponents/Sortingarrow";
import TableHeader from "./TableHeader/TableHeader";
import LMSButton from "../../../Pages/DMS/Common/LMSButton/LMSButton";
import { useTranslation } from "react-i18next";
// import {
//   DoubleLeft,
//   DoubleRight,
//   SingleLeft,
//   SingleRight,
// } from "../../../Components/IconComponents";

const CustomTable = (props) => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    totalrows = false,
    showTotal = false,
    numberOfRows,
    tableStyle = {},
    pagination = true,
    showHeader = true,
    headerMap,
    accessorMap,
    isHeaderFixed = false,
    showFilterColumn,
    materialWiseSumShow = false,
  } = props;
  const hiddenColumns = showFilterColumn
    ? columns
        .filter((column) => !column.showInitially)
        .map((column) => column.accessor)
    : [];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, globalFilter },
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(numberOfRows);
  }, [numberOfRows]);

  const totalSum = accessorMap?.map((accessor) => {
    // Initialize value to 0 for each accessor
    let value = 0;

    data?.forEach((row) => {
      // Ensure that the value is a number before adding it to the total
      const numericValue = parseFloat(row[accessor]);
      if (!isNaN(numericValue)) {
        value += numericValue;
      }
    });

    return value;
  });

  const totalQuantity = useMemo(() => {
    return rows.reduce(
      (sum, row) => sum + parseInt(row.values.quantity, 10),
      0
    );
  }, [rows]);

  return (
    <>
      <div className="iccc__table-header__table-container__wrap">
        {showHeader && (
          <TableHeader
            {...props}
            searchValue={globalFilter || ""}
            allColumns={allColumns}
            toggleHideColumn={toggleHideColumn}
            onSearchHandler={setGlobalFilter}
          />
        )}
        <div className="cust__table--container" style={{ overflowX: "auto" }}>
          <table
            {...getTableProps()}
            className="table iccc--table fixed-width-header">
            <thead>
              {headerGroups?.map((headerGroup, index) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                  className={isHeaderFixed ? "fixed__header" : ""}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      // style={}
                      style={{
                        fontSize: "0.9rem",
                        minWidth: "10rem",
                        borderRight: "white",
                      }}
                      key={index}
                      className={column.leftAlignHeader ? "left-align" : ""}>
                      {t(column.render("Header"))}
                      {/* Add a sort direction indicator */}

                      {!column.disableSortBy &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <Sortdownarrow />
                          ) : (
                            <Sortuparrow />
                          )
                        ) : (
                          <Sortingarrow />
                        ))}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 &&
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              className={
                                cell.column.leftAlign ? "left-align" : ""
                              }>
                              {t(cell.render("Cell"))}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          <div>
            {showTotal && (
              <div className="d-flex justify-content-between w-100 pagination_page position-absolute bottom-0 end-0">
                <span className="edemand-status__planned-value">Total: </span>
                <span className="edemand-status__planned-value">
                  {totalQuantity.toLocaleString()}
                </span>
              </div>
            )}
          </div>
          {page.length === 0 && (
            <div className="no-data-text">No data Found</div>
          )}
        </div>

        {page.length > 0 && pagination && (
          <div className="pagination gap-2">
            <LMSButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={
                !canPreviousPage ? "disabledpagebtn pagebtn" : "pagebtn"
              }
              label={"<"}
              size="btn_vvsm"
              variant="light"
            />
            <LMSButton size="btn_vvsm" label={pageIndex + 1}></LMSButton>
            <LMSButton
              label={">"}
              size="btn_vvsm"
              variant="light"
              className={!canNextPage ? "disabledpagebtn pagebtn" : "pagebtn"}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <span className="right_pageno">
              {pageIndex + 1}/{pageOptions.length}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTable;

import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import "./ColumnFilter.css";
import { Redclosebutton } from "../../../../Components/IconComponents";
import { ThemeContext } from "../../../..";
import { useTranslation } from "react-i18next";
import LMSButton from "../LMSButton/LMSButton";

const ColumnFilter = ({
  allColumns,
  toggleHideColumn,
  showModal,
  handleClose,
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  // State to store the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Function to select all columns
  const handleSelectAll = (data) => {
    data.forEach((column) => {
      if (!column.isVisible) {
        toggleHideColumn(column.id);
      }
    });
  };

  // Function to deselect all columns
  const handleDeselectAll = (data) => {
    data.forEach((column) => {
      if (column.isVisible) {
        toggleHideColumn(column.id);
      }
    });
  };

  // Filter columns based on the search query
  const filteredColumns = allColumns.filter((column) =>
    t(column.render("Header")).toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName="modal-right"
      size="xl"
      id={theme}
      className="modal__header--ColumnFilter"
      backdrop="static"
      keyboard={false}>
      <Modal.Header className="ColumnFilter__modal">
        <Modal.Title>
          <span className="column__filter--title">Column Filter</span>
        </Modal.Title>
        <Redclosebutton
          onClick={handleClose}
          width="1.5em"
          height="1.5em"
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body className="ColumnFilter__modal--body">
        <div className="filter__modal--body">
          <input
            type="text"
            placeholder="Search columns..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="column-filter-search"
          />

          <div className="d-flex gap-2 mt-2 justify-content-center">
            <LMSButton
              label={" Select All"}
              onClick={() => handleSelectAll(filteredColumns)}
            />
            <LMSButton
              label={"  Deselect All"}
              onClick={()=>handleDeselectAll(filteredColumns)}
            />
          </div>

          {/* Filtered checkbox list */}
          <ul className="checkbox-list">
            {filteredColumns?.map((column) => {
              if (column.id !== "selected") {
                return (
                  <li key={column.id}>
                    <div className="filter__container mt-2">
                      <label style={{ width: "90%" }}>
                        {t(column.render("Header"))}
                        <input
                          type="checkbox"
                          checked={column.isVisible}
                          onChange={() => toggleHideColumn(column.id)}
                        />
                        <span className="checkbox__checked"></span>
                      </label>
                    </div>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ColumnFilter;

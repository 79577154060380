import React from "react";

import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Table from "../../../../Components/UiComponents/Table/Table";
import { getMealsDetailsURL } from "../../../../Services/EndPoints";
import { postDataWithBody } from "../../../../Services/Services";

const CommunityKitchenBreakFastDetails = (props) => {
  const { t } = useTranslation();
  const [breakfastData, setBreakFastData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    getMealsDetailsData(props.mealTypeCd, props.facilityreportId);
  }, []);

  const getMealsDetailsData = async (mealTypeCd, facilityreportId) => {
    let inputobj = {
      facilityReportIds: [parseInt(facilityreportId)],
      facilityOfferingTypeCd: mealTypeCd,
    };
    var mealdataarr = await postDataWithBody(getMealsDetailsURL, inputobj, {});

    let totalAmount = 0;
    mealdataarr.forEach(function (data, node) {
      if (
        data.facility_report_attribute_value &&
        !isNaN(data.facility_report_attribute_value)
      ) {
        totalAmount += parseInt(data.facility_report_attribute_value);
      }
    });
    mealdataarr.push({
      attribute: "Total",
      facility_report_attribute_value: totalAmount,
    });
    setRowCount(mealdataarr.length);
    setBreakFastData(mealdataarr);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("communitykitchenpopuptable.name"),
        accessor: "attribute",
        disableSortBy: true,
      },
      {
        Header: t("communitykitchenpopuptable.value"),
        accessor: "facility_report_attribute_value",
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {breakfastData && (
            <Table
              data={breakfastData}
              addButtonLabel="Create UserTask"
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount} // Pass rowCount to the Table component
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
              applyTableHeaderCustomClass={true}
              disbalePaginationFilter={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityKitchenBreakFastDetails;

import React, { useEffect } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import MISDistFloodReportTable from "./MISDistFloodReportTable";
import MISFloodReportTable from "./MISFloodReportTable";

const MISDistFloodReport = () => {
  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="MIS District Flood Report"
          fileName="DailyFloodReportStatus.csv"
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 20,
      id: 2,
      component: <MISFloodReportTable />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default MISDistFloodReport;
